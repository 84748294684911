e4.header = {
    init: function () {
        // console.log( 'e4.header.autoHideTolerance', e4.settings.header.autoHideTolerance.down );
        var jHeader = jQuery( '.js-e-header' );
        if ( jHeader.length === 1 ) {
            // If the page has sticky sections or local horizontal navigation, the header cannot be sticky.
            if ( jQuery( '.js-e-section.is-sticky, .e-nav-local-horizontal.is-sticky:visible' ).length > 0 ) {
                e4.header.disableSticky();
            }
            e4.header.canAutoHide            = jHeader.data( 'autohide' );
            e4.header.intScrollTopLast       = jQuery( window ).scrollTop();
            e4.header.strScrollDirectionLast = 'none';
            e4.header.isTransitioning        = false;
            e4.header.isShown                = true;
            e4.header.isRAF                  = false;
            e4.header.event                  = e4.util.registerEvents( 'hide hidden show shown', 'header' );
            e4.header.bindEvents();
        }
    },
    bindEvents: function () {
        jQuery( window ).on( 'scroll', function () {
            // console.log( 'e4.header.isRAF', e4.settings.header.autoHideTolerance[ strScrollDirection
            // ], strScrollDirection );
            if ( e4.header.isRAF === false ) {
                window.requestAnimationFrame( function () {
                    e4.header.toggleSticky();
                    e4.header.toggleVisibility();
                    e4.header.isRAF = false;
                } );
                e4.header.isRAF = true;
            }
//            else {
//                console.warn( 'header is busy requestAnimationFrame' );
//            }
        } );
    },
    enableSticky: function () {
        jQuery( '.js-e-header' ).addClass( 'is-sticky' );
    },
    disableSticky: function () {
        jQuery( '.js-e-header' ).removeClass( 'is-sticky' );
    },
    toggleSticky: function () {
        if ( jQuery( window ).scrollTop() > 0 ) {
            jQuery( '.js-e-header.is-sticky' ).addClass( 'is-stuck' );
        }
        else {
            jQuery( '.js-e-header.is-sticky' ).removeClass( 'is-stuck' );
        }
    },
    toggleVisibility: function () {
        if ( e4.header.canAutoHide === true ) {
            var intScrollTop       = jQuery( window ).scrollTop(),
                strScrollDirection = intScrollTop >= e4.header.intScrollTopLast ? 'down' : 'up';
            if ( intScrollTop <= e4.settings.header.autoHideTolerance.down * 2 ) {
                e4.header.show();
            }
            else if ( Math.abs( intScrollTop - e4.header.intScrollTopLast ) > e4.settings.header.autoHideTolerance[ strScrollDirection ] ) {
                if ( strScrollDirection === 'down' && strScrollDirection !== e4.header.strScrollDirectionLast ) {
                    if ( jQuery( '.js-e-header' ).find( '.dropdown.show, .modal.show' ).length === 0 ) {
                        e4.header.hide();
                    }
                }
                else if ( strScrollDirection === 'up' && strScrollDirection !== e4.header.strScrollDirectionLast ) {
                    e4.header.show();
                }
                e4.header.intScrollTopLast       = intScrollTop;
                e4.header.strScrollDirectionLast = strScrollDirection;
            }
        }
    },
    show: function () {
        if ( e4.header.isTransitioning === false && e4.header.isShown === false ) {
            var jHeader = jQuery( '.js-e-header' );
            jHeader.trigger( e4.header.event.show );
            if ( e4.settings.event.transitionend !== false ) {
                e4.header.isTransitioning = true;
                jHeader.one( e4.settings.event.transitionend, function () {
                    e4.header.isTransitioning = false;
                    jHeader.trigger( e4.header.event.shown );
                } );
            }
            else {
                jHeader.trigger( e4.header.event.shown );
            }
            jHeader.removeClass( 'is-hidden' );
            e4.header.isShown = true;
        }
//        else {
//            console.log( 'show condition not met', e4.header.isTransitioning, e4.header.isShown );
//        }
    },
    hide: function () {
        if ( ( e4.header.isTransitioning === false && e4.header.isShown === true ) && e4.header.canAutoHide === true ) {
            var jHeader = jQuery( '.js-e-header' );
            jHeader.trigger( e4.header.event.hide );
            if ( e4.settings.event.transitionend !== false ) {
                e4.header.isTransitioning = true;
                jHeader.one( e4.settings.event.transitionend, function () {
                    e4.header.isTransitioning = false;
                    jHeader.trigger( e4.header.event.hidden );
                } );
            }
            else {
                jHeader.trigger( e4.header.event.hidden );
            }
            jHeader.addClass( 'is-hidden' );
            e4.header.isShown = false;
        }
//        else {
//            console.log( 'wont hide header', e4.header.isTransitioning, e4.header.isShown, e4.header.canAutoHide );
//        }
    }
};

