e4.product = {
    added: '',
    init: function () {
        if ( jQuery( '.js-e-product-form, .js-e-productlist' ).length > 0 ) {
            e4.util.require.js( e4.settings.require.jQueryValidate, e4.product.bindEvents );
        }
    },
    bindEvents: function () {
        jQuery( '.js-e-product-form' ).each( function ( intIndex, domForm ) {
            e4.product.validateForm( domForm );
        } );
        jQuery( '.js-e-productlist' ).on( 'builded.e4.productlist', function ( event ) {
            jQuery( event.target ).find( '.js-e-product-form' ).each( function ( intIndex, domForm ) {
                e4.product.validateForm( domForm );
            } );
            e4.product.updateProductData();
        } );
    },
    validateForm: function ( domForm ) {
        var jForm = jQuery( domForm );
        jForm.validate( {
            submitHandler: function ( domForm ) {
                if ( jForm.is( '.js-e-product-form-removefromcart' ) === true ) {
                    e4.product.removeFromCart( domForm );
                }
                else /* if ( jForm.is( '.js-e-product-form-addtocart' ) === true )*/ {
                    e4.product.added = jQuery( domForm ).find( 'input[name="ProductID"]' ).val();
                    e4.product.addToCart( domForm );
                }
            }
        } );
    },
    addToCart: function ( domForm ) {
        var jForm       = jQuery( domForm ),
            arrFormData = jForm.serializeArray(),
            objFormData = {};
        for ( var i = 0; i < arrFormData.length; i++ ) {
            objFormData[ arrFormData[ i ].name ] = arrFormData[ i ].value;
        }
        jQuery.extend( objFormData, {
            Redirect: false
        } );
        if ( jForm.is( '.js-e-product-form-download' ) === true ) {
            e4.download.cart.addProduct( objFormData );
        }
        else {
            e4.cart.addProduct( objFormData, e4.product.updateProductData );
        }
    },
    getOrderlineById: function ( objOrderlines, orderlineId ) {
        var result       = {},
            orderlineArr = objOrderlines.filter( function ( orderline ) {
                return orderline.id === orderlineId;
            } );
        if ( orderlineArr.length === 1 ) {
            Object.assign( result, orderlineArr[ 0 ] );
        }
        return result;
    },
    getOrderlineByProductId: function ( objOrderlines, productId ) {
        var result       = {},
            orderlineArr = objOrderlines.filter( function ( orderline ) {
                return orderline.product.id === productId;
            } );
        if ( orderlineArr.length === 1 ) {
            Object.assign( result, orderlineArr[ 0 ] );
        }
        return result;
    },
    _cartData: {},
    updateProductData: function ( cartData ) {
        if ( cartData ) {
            e4.product._cartData = cartData;
        }
        else {
            cartData = e4.product._cartData;
        }
        if ( cartData.cart !== undefined && cartData.cart.orderlineChanged.Changes.length > 0 ) {
            var changes = cartData.cart.orderlineChanged.Changes;
            for ( var i = 0; i < changes.length; i++ ) {
                var orderline = e4.product.getOrderlineById( cartData.cart.orderlines, changes[ i ].OrderLineId );
                if ( Object.keys( orderline ).length > 0 ) {
                    jQuery( '.js-e-product-current-stock-level' ).text( orderline.product.stock.leftInStock );
                }
            }
        }
        else if ( jQuery( '.js-e-product-form' ).length > 0 ) {
            jQuery( '.js-e-product-form' ).each( function ( index, form ) {
                var productId = jQuery( form ).find( 'input[name="ProductID"]' ).val(),
                    isOutlet  = jQuery( form ).find( 'input[name="EcomOrderLineFieldInput_Outlet"]' );
                if ( isOutlet.length > 0 && isOutlet.val() === 'outlet' ) {
                    if ( cartData.cart !== undefined ) {
                        var orderline = e4.product.getOrderlineByProductId( cartData.cart.orderlines, productId );
                        if ( Object.keys( orderline ).length > 0 ) {
                            jQuery( form ).find( '.js-e-product-quantity' ).data( 'stock', orderline.product.stock.leftInStock ).attr( 'max', orderline.product.stock.leftInStock );
                            jQuery( form ).closest( '.js-babydan-product-item' ).find( '.js-e-product-current-stock-level' ).text( orderline.product.stock.leftInStock );
                        }
                    }
                }
            } );
        }
    },
    removeFromCart: function ( domForm ) {
        var jForm       = jQuery( domForm ),
            arrFormData = jForm.serializeArray(),
            objFormData = {};
        for ( var i = 0; i < arrFormData.length; i++ ) {
            objFormData[ arrFormData[ i ].name ] = arrFormData[ i ].value;
        }
        jQuery.extend( objFormData, {
            Redirect: false
        } );
        if ( jForm.is( '.js-e-product-form-download' ) === true ) {
            e4.download.cart.removeProduct( objFormData );
        }
        else {
            e4.cart.removeProduct( objFormData );
        }
    }
};
