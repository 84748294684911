e4.gtm = {
    debug: false,
    init: function () {
        if ( window.google_tag_manager !== undefined ) {
            e4.gtm.bindEvents();
        }
    },
    bindEvents: function () {
        jQuery( document )
            .on( 'userauthenticated.e4.login', e4.gtm.login )
            .on( 'productadded.e4.cart', e4.gtm.ecom.addProduct )
            .on( 'productremoved.e4.cart', e4.gtm.ecom.removeProduct );
        jQuery( '.js-e-search-form' )
            .on( 'loaded.e4.search', e4.gtm.search );
        jQuery( '.js-e-checkout, .js-e-checkout-receipt' )
            .on( [
                'stepchanged.e4.checkout',
                'receiptshown.e4.checkout'
            ].join( ' ' ), e4.gtm.ecom.checkoutStep );
    },
    pushDataLayer: function ( objDataLayer ) {
        if ( window.dataLayer !== undefined ) {
            dataLayer.push( objDataLayer );
        }
    },
    login: function () {
        e4.gtm.pushDataLayer( {
            'LoggedOn': 'OK',
            'event': 'LoggedOnDone'
        } );
    },
    search: function ( event, objData ) {
        e4.gtm.pushDataLayer( {
            'event': 'VirtualPageviewSearch',
            'virtualPageSearchURL': '/search?q=' + objData.query,
            'virtualPageSearchTitle': 'Search'
        } );
        if ( objData.resultCount === 0 ) {
            e4.gtm.pushDataLayer( {
                'eventCategory': 'Search - Zero results',
                'eventAction': objData.query,
                'eventLabel': '{{Page URL}}',
                'eventValue': 0,
                'eventInteraction': true,
                'event': 'track-ga-event'
            } );
        }
    },
    ecom: {
        // https://developers.google.com/tag-manager/enhanced-ecommerce#cart
        addProduct: function ( event, objData ) {
            // console.log( 'GTM: Add product', objData );
            e4.gtm.pushDataLayer( {
                'event': 'addToCart',
                'ecommerce': {
                    'currencyCode': objData.cart.currencyCode,
                    'add': {
                        'products': [
                            {
                                'name': objData.cart.lastAdded[ 0 ].name,
                                'id': objData.cart.lastAdded[ 0 ].number,
                                'price': objData.cart.lastAdded[ 0 ].priceClean,
                                'quantity': objData.cart.lastAdded[ 0 ].quantity
                            }
                        ]
                    }
                }
            } );
        },
        removeProduct: function ( event, objData ) {
            //console.log( 'GTM: Remove product', objData );
            e4.gtm.pushDataLayer( {
                'event': 'removeFromCart',
                'ecommerce': {
                    'currencyCode': objData.currencyCode,
                    'remove': {
                        'products': [
                            {
                                'name': objData.productName,
                                'id': objData.productNumber,
                                'price': objData.productPrice,
                                'quantity': objData.productQuantity
                            }
                        ]
                    }
                }
            } );
        },
        checkoutStep: function ( event, objData ) {
            // e4 checkout index is zero based, so we bump up index to honor GA's flow which begins at one.
            var intCheckoutStepIndexForGA = parseInt( objData.index ) + 1;
            e4.util.debug( [
                'checkoutStep objData',
                objData,
                'intCheckoutStepIndexForGA',
                intCheckoutStepIndexForGA
            ], e4.gtm.debug );
            if ( intCheckoutStepIndexForGA > 0 && objData.name !== '' ) {
                var objVirtualPageview = {
                        'event': 'VirtualPageview',
                        'vpevent': 'VirtualPageview',
                        'virtualPageURL': '/shop/cart/checkout?step=' + intCheckoutStepIndexForGA + ' ' + objData.name,
                        'virtualPageTitle': 'Order Step ' + intCheckoutStepIndexForGA + ' – ' + objData.name
                    },
                    objCheckout = {
                        'event': 'checkout',
                        'ecommerce': {
                            'checkout': {
                                'actionField': {
                                    'step': intCheckoutStepIndexForGA
                                }
                            }
                        }
                    };
                e4.gtm.pushDataLayer( objVirtualPageview );
                e4.gtm.pushDataLayer( objCheckout );
            }
        }
    }
};
