e4.handlebars = {
    tmpl: {},
    registerTemplates: function ( strTemplates ) {
        var arrTemplates = strTemplates.split( ' ' );
        for ( var i = 0; i < arrTemplates.length; i++ ) {
            if ( jQuery( arrTemplates[ i ] ).length === 1 ) {
                var tmplName = jQuery.camelCase( arrTemplates[ i ].replace( '#js-e-handlebars-tmpl-', '' ) );
                e4.handlebars.tmpl[ tmplName ] = Handlebars.compile( jQuery( arrTemplates[ i ] ).html() );
            }
        }
    },
    partial: {},
    registerPartials: function ( strPartials ) {
        var arrPartials = strPartials.split( ' ' );
        for ( var i = 0; i < arrPartials.length; i++ ) {
            if ( jQuery( arrPartials[ i ] ).length === 1 ) {
                var partialName = jQuery.camelCase( arrPartials[ i ].replace( '#js-e-handlebars-partial-', '' ) );
                Handlebars.registerPartial( partialName, jQuery( arrPartials[ i ] ).html() );
                e4.handlebars.partial[ partialName ] = Handlebars.compile( jQuery( arrPartials[ i ] ).html() );
            }
        }
    },
    registerHelpers: function ( strHelpers ) {
        var arrHelpers = strHelpers.split( ' ' );
        for ( var i = 0; i < arrHelpers.length; i++ ) {
            if ( window.Handlebars.helpers[ arrHelpers[ i ] ] === undefined ) {
                e4.handlebars.helpers[ arrHelpers[ i ] ]();
            }
        }
    },
    helpers: {
        compare: function () {
            Handlebars.registerHelper( 'compare', function ( v1, operator, v2, options ) {
                switch ( operator ) {
                    case '===':
                        return ( v1 === v2 ) ? options.fn( this ) : options.inverse( this );
                    case '!==':
                        return ( v1 !== v2 ) ? options.fn( this ) : options.inverse( this );
                    case '<':
                        return ( v1 < v2 ) ? options.fn( this ) : options.inverse( this );
                    case '<=':
                        return ( v1 <= v2 ) ? options.fn( this ) : options.inverse( this );
                    case '>':
                        return ( v1 > v2 ) ? options.fn( this ) : options.inverse( this );
                    case '>=':
                        return ( v1 >= v2 ) ? options.fn( this ) : options.inverse( this );
                    case '&&':
                        return ( v1 && v2 ) ? options.fn( this ) : options.inverse( this );
                    case '||':
                        return ( v1 || v2 ) ? options.fn( this ) : options.inverse( this );
                    case '!!':
                        return ( !v1 && !v2 ) ? options.fn( this ) : options.inverse( this );
                    case '!|':
                        return ( !v1 && v2 ) ? options.fn( this ) : options.inverse( this );
                    case '|!':
                        return ( v1 && !v2 ) ? options.fn( this ) : options.inverse( this );
                    default:
                        return options.inverse( this );
                }
            } );
        },
        counter: function () {
            Handlebars.registerHelper( 'counter', function ( index ) {
                return index + 1;
            } );
        }
    }
};

