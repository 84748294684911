// https://api.jquery.com/jquery.extend/#jQuery-extend-deep-target-object1-objectN
( function () {
    'use strict';
    jQuery.extend( true, e4.settings, {
        search: {
            result: {
                products: {
                    max: {
                        xs: 2,
                        sm: 2,
                        md: 4,
                        lg: 6,
                        xl: 8
                    }
                }
            }
        }
    } );
}() );
