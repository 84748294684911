var babydan = babydan || {};
( function () {
    'use strict';
    babydan.init       = function () {
        babydan.navigation.init();
        babydan.socials.init();
        babydan.ecom.init();
    };
    babydan.navigation = {
        init: function () {
            babydan.navigation.createSeeAllLink();
            babydan.navigation.inlineSearch();
        },
        createSeeAllLink: function () {
            jQuery( '.js-last-of-navigation-group' ).each( function ( index, element ) {
                var lastMenuItemClone = jQuery( element ).clone(),
                    megamenuNavItem   = jQuery( element ).closest( '.e-megamenu-nav' ),
                    mainMenuItem      = jQuery( element ).closest( '.e-megamenu-item' ).find( '.js-see-all-link' );
                if ( megamenuNavItem.is( '.last-is-true' ) === false ) {
                    lastMenuItemClone.find( '.nav-link' ).attr( 'href', mainMenuItem.attr( 'href' ) );
                    lastMenuItemClone.find( '.e-nav-pagename' ).text( 'See all' );
                    jQuery( element ).after( lastMenuItemClone );
                }
            } );
        },
        inlineSearch: function () {
            jQuery( '.js-e-header' ).find( '.js-babydan-inline-search-form' ).on( 'submit', function ( event ) {
                event.preventDefault();
                var strQuery = jQuery( event.target ).find( '.js-e-search-input' ).val();
                if ( e4.util.hash.get( 'search' ) === undefined ) {
                    e4.util.hash.set( 'search' );
                    jQuery( '.js-e-search-modal' ).find( '.js-e-search-input' ).val( strQuery );
                }
            } );
            jQuery( '.js-e-search-modal' ).on( 'hide.bs.modal', function () {
                e4.util.hash.remove( 'search' );
            } );
        }
    };
    babydan.socials    = {
        init: function () {
            babydan.socials.activeCampaign.init();
            babydan.socials.instagram.init();
        },
        activeCampaign: {
            init: function () {
                var jElementWrapper = jQuery( '.js-custom-active-campaign-wrapper' );
                if ( jElementWrapper.length > 0 ) {
                    jQuery( '.js-custom-active-campaign-wrapper style' ).remove();
                    jQuery( '.js-custom-active-campaign-wrapper' ).addClass( 'active-campaign-form' );
                    jElementWrapper.find( '._submit' ).addClass( 'btn btn-primary' );
                    jElementWrapper.find( '._form-branding' ).addClass( 'd-none' );
                }
            }
        },
        instagram: {
            init: function () {
                var domElmInstagram = document.getElementById( 'instafeed' );
                if ( domElmInstagram !== null ) {
                    jQuery.getScript( '/Files/Templates/Designs/babydan/_assets/js/vendor/instafeed.js', function () {
                        babydan.socials.instagram.build( domElmInstagram );
                    } );
                }
            },
            getTemplate: function () {
                var domStrCaptions = '<div class="instagram-feed-item-caption"><p>{{caption}}</p></div>',
                    domStrItem     = '<a class="instagram-feed-item" href="{{link}}" target="_blank" style="background-image:url({{image}})"><img src="/Files/Templates/Designs/babydan/_assets/img/trans.png" alt="{{caption}}">' + domStrCaptions + '</a>';
                return domStrItem;
            },
            build: function ( domElmInstagram ) {
                var feed = new Instafeed( {
                    accessToken: domElmInstagram.dataset.token,
                    limit: parseInt( domElmInstagram.dataset.limit ),
                    target: 'instagram-feed',
                    debug: false,
                    filter: null,
                    sort: null,
                    transform: null,
                    after: function () {
                        domElmInstagram.removeAttribute( 'data-token' );
                        var feedItems = domElmInstagram.querySelectorAll( '.instagram-feed-item' );
                        for ( var i = 0; i < feedItems.length; i++ ) {
                            feedItems[ i ].classList.add( 'instagram-feed-item-' + i );
                        }
                    },
                    template: babydan.socials.instagram.getTemplate()
                } );
                feed.run();
            }
        }
    };
    babydan.ecom       = {
        init: function () {
            babydan.ecom.bindEvents();
        },
        bindEvents: function () {
            jQuery( document ).on( 'input blur', '.js-e-product-quantity', function ( event ) {
                if ( jQuery( event.target ).is( '.is-invalid' ) === true ) {
                    var dataErrorMessage = jQuery( event.target ).data( 'msgMax' );
                    jQuery( event.target ).closest( '.js-babydan-product-item' ).find( '.js-e-product-quantity-error-msg' ).text( dataErrorMessage ).removeClass( 'd-none' ).addClass( 'd-inline-block' );
                }
                else {
                    jQuery( event.target ).closest( '.js-babydan-product-item' ).find( '.js-e-product-quantity-error-msg' ).text( '' ).removeClass( 'd-inline-block' ).addClass( 'd-none' );
                }
            } );
        }
    };
}() );
