e4.cta = {
    init: function () {
        var jCTA = jQuery( '.js-e-cta' ),
            jHeader = jQuery( '.js-e-header' );
        if ( jCTA.length === 1 && jHeader.length === 1 ) {
            jHeader.on( 'hidden.e4.header', function () {
                e4.cta.show();
            } ).on( 'show.e4.header', function () {
                e4.cta.hide();
            } );
        }
    },
    show: function () {
        jQuery( '.js-e-cta' ).removeClass( 'is-hidden' );
    },
    hide: function () {
        jQuery( '.js-e-cta' ).addClass( 'is-hidden' );
    }
};

