e4.download = {
    init: function () {
        if ( jQuery( '.js-e-download-cart-form' ).length === 1 ) {
            e4.download.cart.init();
        }
        else if ( jQuery( '.js-e-download-cart-quantity' ).length > 0 ) {
            e4.download.event = e4.util.registerEvents( 'add added', 'download' );
            e4.download.cart.updateQuantity();
        }
        else {
            e4.download.event = e4.util.registerEvents( 'add added', 'download' );
        }
        if ( jQuery( '.js-e-productlist-download' ) ) {
            e4.download.event = e4.util.registerEvents( 'add added', 'download' );
            e4.download.list.init();
        }
    },
    ajax: function ( objAjaxSettings, fnCallback ) {
        var jqXHR = jQuery.ajax( jQuery.extend( {
            url: e4.settings.ecom.download.url,
            method: 'POST',
            dataType: 'json',
            cache: false
        }, objAjaxSettings ) );
        jqXHR.done( function ( data ) {
            if ( typeof fnCallback === 'function' ) {
                fnCallback( data );
            }
        } );
    },
    list: {
        init: function () {
            e4.download.list.bindEvents();
        },
        bindEvents: function () {
            jQuery( '.js-e-productlist' ).on( 'builded.e4.productlist', e4.download.list.toogleIsInCart );
            jQuery( '.js-e-download-search-form' ).on( 'submit', function ( event ) {
                event.preventDefault();
                console.log( event.isDefaultPrevented );
                var jForm            = jQuery( event.target ),
                    jProductlistForm = jQuery( '.js-e-productlist-form' );
                jProductlistForm.find( '.js-e-search-input' ).val( jForm.find( '.js-e-search-input' ).val() );
                jProductlistForm.trigger( 'submit' );
                return false;
            } );
            jQuery( '.js-e-download-addall' ).on( 'click', e4.download.list.addAll );
        },
        addAll: function () {
            var objFormData       = {},
                arrAddMultiFields = [
                    'productid',
                    'variantid',
                    'quantity'
                ],
                regexFieldsNames  = new RegExp( arrAddMultiFields.join( '|' ), 'i' );
            jQuery( '.js-e-product-form-addtocart.js-e-product-form-download' ).each( function ( index, element ) {
                var jForm                                   = jQuery( element ),
                    arrFormData                             = jForm.serializeArray();
                objFormData[ 'ProductLoopCounter' + index ] = index;
                for ( var i = 0; i < arrFormData.length; i++ ) {
                    if ( regexFieldsNames.test( arrFormData[ i ].name ) === true ) {
                        objFormData[ arrFormData[ i ].name + index ] = arrFormData[ i ].value;
                    }
                }
            } ).closest( '.js-e-products-item' ).addClass( 'is-in-cart' );
            e4.download.cart.addMultipleProducts( objFormData );
        },
        toogleIsInCart: function () {
            e4.download.ajax( {
                method: 'GET'
            }, function ( data ) {
                jQuery.each( data.cart.orderlines, function ( index, value ) {
                    jQuery( '.js-e-products-item-' + value.product.id + '[data-variant-id="' + value.product.variantId + '"]' ).addClass( 'is-in-cart' );
                } );
            } );
        }
    },
    cart: {
        init: function () {
            e4.util.require.js( e4.settings.require.handlebars, function () {
                e4.handlebars.registerHelpers( 'compare' );
                e4.handlebars.registerTemplates( '#js-e-handlebars-tmpl-cart-orderlines #js-e-handlebars-tmpl-cart-summery' );
                e4.download.event = e4.util.registerEvents( 'load loaded add added update updated', 'download' );
                e4.download.cart.bindEvents();
                e4.download.orderline.init();
                e4.download.cart.load();
            } );
        },
        bindEvents: function () {
            jQuery( '.js-e-download-cart-email-add-btn' ).on( 'click', e4.download.cart.addEmail );
        },
        load: function () {
            var jDownloadCart = jQuery( '.js-e-download-cart' );
            jDownloadCart.trigger( e4.download.event.load );
            e4.download.ajax( {
                method: 'GET',
                beforeSend: function () {
                    jDownloadCart.find( '.js-e-loading-overlay' ).addClass( 'is-loading' );
                }
            }, function ( data ) {
                e4.download.cart.update( data );
                jDownloadCart.trigger( e4.download.event.loaded );
            } );
        },
        addProduct: function ( strObjProduct, fnCallback ) {
            var jDownloadCart = jQuery( '.js-e-download-cart' );
            jDownloadCart.trigger( e4.download.event.add );
            var objProductData = {
                OrderContext: 'Download',
                CartCmd: 'add',
                ProductID: '',
                VariantID: '',
                Quantity: 1,
                Redirect: false
            };
            if ( jQuery.isPlainObject( strObjProduct ) === true ) {
                jQuery.extend( objProductData, strObjProduct );
            }
            else if ( jQuery.type( strObjProduct ) === 'string' ) {
                objProductData.ProductID = strObjProduct;
            }
            if ( objProductData.ProductID.indexOf( 'PROD' ) === 0 ) {
                e4.download.ajax( {
                    data: objProductData,
                    beforeSend: function () {
                        var jDownloadCart = jQuery( '.js-e-download-cart' );
                        if ( jDownloadCart.length === 1 ) {
                            jDownloadCart.find( '.js-e-loading-overlay' ).addClass( 'is-loading' );
                        }
                    }
                }, function ( data ) {
                    if ( data.cart.lastAdded.length !== 0 ) {
                        jQuery( '.js-e-products-item-' + data.cart.lastAdded[ 0 ].id ).addClass( 'is-in-cart' );
                    }
                    if ( jDownloadCart.length === 1 ) {
                        e4.download.cart.update( data );
                        jDownloadCart.find( '.js-e-loading-overlay' ).removeClass( 'is-loading' );
                        e4.download.orderline.focus( data.cart.lastAdded[ 0 ].id );
                    }
                    e4.download.cart.updateQuantity( data.cart.productLinesCount );
                    if ( typeof fnCallback === 'function' ) {
                        fnCallback( data );
                    }
                    jDownloadCart.trigger( e4.download.event.added );
                } );
            }
        },
        addMultipleProducts: function ( strObjProduct, fnCallback ) {
            var jDownloadCart = jQuery( '.js-e-download-cart' );
            jDownloadCart.trigger( e4.download.event.add );
            var objProductData = {
                OrderContext: 'Download',
                CartCmd: 'addmulti',
                Redirect: false
            };
            if ( jQuery.isPlainObject( strObjProduct ) === true ) {
                jQuery.extend( objProductData, strObjProduct );
                e4.download.ajax( {
                    data: objProductData
                }, function ( data ) {
                    e4.download.cart.updateQuantity( data.cart.productLinesCount );
                    if ( typeof fnCallback === 'function' ) {
                        fnCallback( data );
                    }
                } );
            }
        },
        removeProduct: function ( strObjProduct, fnCallback ) {
            var objProductData = {
                OrderContext: 'Download',
                CartCmd: 'delorderline',
                key: '',
                Redirect: false
            };
            if ( jQuery.isPlainObject( strObjProduct ) === true ) {
                jQuery.extend( objProductData, strObjProduct );
            }
            else if ( jQuery.type( strObjProduct ) === 'string' ) {
                objProductData.ProductID = strObjProduct;
            }
            if ( objProductData.ProductID.indexOf( 'PROD' ) === 0 ) {
                e4.download.ajax( {
                    method: 'GET'
                }, function ( data ) {
                    jQuery.each( data.cart.orderlines, function ( index, value ) {
                        if ( value.product.id === objProductData.ProductID && value.product.variantId === objProductData.VariantID ) {
                            objProductData.key = value.id;
                            if ( objProductData.key.indexOf( 'OL' ) === 0 ) {
                                e4.download.ajax( {
                                    method: 'GET',
                                    data: objProductData
                                }, function ( data ) {
                                    jQuery( '.js-e-products-item-' + objProductData.ProductID + '[data-variant-id="' + objProductData.VariantID + '"]' ).removeClass( 'is-in-cart' );
                                    e4.download.cart.updateQuantity( data.cart.productLinesCount );
                                    if ( typeof fnCallback === 'function' ) {
                                        fnCallback( data );
                                    }
                                } );
                                return false;
                            }
                        }
                    } );
                } );
            }
        },
        update: function ( data ) {
            var jDownloadCart = jQuery( '.js-e-download-cart' );
            jDownloadCart.trigger( e4.download.event.update );
            jQuery( '.js-e-download-cart-orderlines' ).html( e4.handlebars.tmpl.cartOrderlines( data ) );
            e4.loading.resolveWait( '.js-e-download-cart-orderlines' );
            e4.download.cart.updateQuantity( data.cart.productLinesCount );
            jDownloadCart.find( '.js-e-loading-overlay' ).removeClass( 'is-loading' );
            jDownloadCart.trigger( e4.download.event.updated );
        },
        updateQuantity: function ( intQuantity ) {
            var jDownloadCartQuantity = jQuery( '.js-e-download-cart-quantity' );
            if ( jDownloadCartQuantity.length > 0 ) {
                if ( jQuery.isNumeric( intQuantity ) === false ) {
                    e4.download.ajax( {
                        method: 'GET'
                    }, function ( data ) {
                        if ( jQuery.isNumeric( data.cart.productLinesCount ) === true ) {
                            e4.download.cart.updateQuantity( data.cart.productLinesCount );
                        }
                    } );
                }
                else if ( intQuantity === 0 ) {
                    jDownloadCartQuantity.html( '' );
                }
                else {
                    jDownloadCartQuantity.html( intQuantity );
                }
            }
        },
        addEmail: function () {
            var jControlGroupEmail    = jQuery( '.js-e-download-cart-email-control-group' ),
                jControlGroupEmailNew = jControlGroupEmail.first().clone();
            jControlGroupEmailNew.find( '.js-e-download-cart-email' ).removeClass( 'required' ).attr( 'id', function () {
                return this.id + new Date().getTime();
            } ).val( '' );
            jControlGroupEmail.last().after( jControlGroupEmailNew );
            jControlGroupEmailNew.find( '.js-e-download-cart-email' ).trigger( 'focus' );
        }
    },
    orderline: {
        init: function () {
            e4.download.orderline.bindEvents();
        },
        bindEvents: function () {
            e4.download.cart.updateOrderlineTimeout = null;
            jQuery( '.js-e-download-cart-form' ).on( 'click', '.js-e-orderline-update', function ( event ) {
                event.preventDefault();
                e4.download.orderline.update( this );
            } ).on( 'click', '.js-e-orderline-delete-btn', function ( event ) {
                event.preventDefault();
                e4.download.orderline.delete( this );
            } ).on( 'click', '.js-e-orderline-delete-undo-btn', function ( event ) {
                event.preventDefault();
                e4.download.orderline.deleteUndo( this );
            } );
        },
        update: function ( domElm ) {
            var jDownloadCart        = jQuery( '.js-e-download-cart' ),
                jForm                = jQuery( domElm ).closest( '.js-e-download-cart-form' ),
                jOrderline           = jQuery( domElm ).closest( '.js-e-orderline' ),
                intOrderlineQuantity = parseInt( jOrderline.find( '.js-e-orderline-quantity-input' ).val() );
            if ( intOrderlineQuantity !== 0 ) {
                jForm.find( '.js-e-download-cart-form-cartcmd' ).val( 'updateorderlines' );
                e4.download.ajax( {
                    data: jForm.serialize(),
                    beforeSend: function () {
                        jDownloadCart.find( '.js-e-loading-overlay' ).addClass( 'is-loading' );
                    }
                }, function ( data ) {
                    e4.download.cart.update( data );
                    jDownloadCart.find( '.js-e-loading-overlay' ).removeClass( 'is-loading' );
                    e4.download.orderline.focus( jOrderline.attr( 'id' ) );
                } );
            }
            else {
                e4.download.orderline.delete( domElm );
            }
        },
        delete: function ( domElm ) {
            var jOrderline = jQuery( domElm ).closest( '.js-e-orderline' );
            e4.download.ajax( {
                method: 'GET',
                data: {
                    OrderContext: 'Download',
                    CartCmd: 'delorderline',
                    key: jOrderline.attr( 'id' ),
                    Redirect: false
                }
            }, function ( data ) {
                jQuery( domElm ).closest( '.js-e-orderline' ).addClass( 'is-deleted' );
                e4.download.cart.updateQuantity( data.cart.productLinesCount );
            } );
        },
        deleteUndo: function ( domElm ) {
            var jOrderline = jQuery( domElm ).closest( '.js-e-orderline' ),
            objOrderlineData= jOrderline.data( );
            e4.download.ajax( {
                data: {
                    OrderContext: 'Download',
                    CartCmd: 'add',
                    ProductID: objOrderlineData.productId,
                    VariantID: objOrderlineData.productVariantId,
                    Quantity: 1,
                    Redirect: false
                }
            }, function ( data ) {
                jOrderline.removeClass( 'is-deleted' );
                jOrderline.attr( 'id', data.cart.orderlines[ data.cart.orderlines.length - 1 ].id );
                e4.download.cart.updateQuantity( data.cart.productLinesCount );
            } );
        },
        focus: function ( strIdentifier ) {
            var jOrderline = jQuery( '.js-e-orderline' ).filter( '#' + strIdentifier + ',[data-product="' + strIdentifier + '"]' );
            if ( jOrderline.length === 1 ) {
                jOrderline.find( '.js-e-orderline-quantity-input' ).focus();
            }
        }
    }
};

