e4.login = {
    init: function () {
        // ATTENTION: Please note that all events are trigger on jQuery( document ) and not the login form.
        e4.login.event = e4.util.registerEvents( 'userauthenticated', 'login' );
        e4.login.checkUserAuthentication();
        e4.login.remember();
    },
    checkUserAuthentication: function () {
        // Check to see if user is authenticated
        if ( jQuery( 'body' ).data( 'userauthenticated' ) === true ) {
            // Check to see if knew that already
            if ( sessionStorage.getItem( 'e4.login.userauthenticated' ) !== 'true' ) {
                // And if we didn't, we can trigger login event
                jQuery( document ).trigger( e4.login.event.userauthenticated );
            }
            // Make sure that we remember this for next page load
            sessionStorage.setItem( 'e4.login.userauthenticated', 'true' );
        }
        else {
            // Invalidate info if user logs off.
            sessionStorage.setItem( 'e4.login.userauthenticated', 'false' );
        }
    },
    remember: function () {
        jQuery( '.js-e-login-remember' ).on( 'change', function () {
            jQuery( this.form ).find( '.js-e-login-remember-username, .js-e-login-remember-password' ).val( ( jQuery( this ).prop( 'checked' ) === true ? 'True' : '' ) );
        } ).triggerHandler( 'change' );
    }
};
