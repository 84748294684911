e4.animateTxt = {
    init: function () {
        var jTxt = jQuery( '.p-txt-animate' );
        if ( jTxt.length > 0 ) {
            e4.animateTxt.animate();
            e4.animateTxt.bindEvents();
        }
    },
    bindEvents: function () {
        e4.animateTxt.isRAF = false;
        jQuery( window ).on( 'scroll', function () {
            if ( e4.animateTxt.isRAF === false ) {
                window.requestAnimationFrame( function () {
                    e4.animateTxt.animate();
                    e4.animateTxt.isRAF = false;
                } );
                e4.animateTxt.isRAF = true;
            }
        } );
    },
    animate: function () {
        jQuery( '.p-txt-animate' ).each( function () {
            var jThis = jQuery( this );
            if ( e4.util.isInViewport( jThis ) === true ) {
                jThis.addClass( 'is-visible' );
            }
        } );
    }

};

