e4.bootstrap.collapse = {
    init: function () {
//        e4.bootstrap.collapse.peekContent();
//        e4.bootstrap.collapse.bindEvents();
    },
    bindEvents: function () {
//        jQuery( '.collapse-peek' ).on( 'click', '.collapse:not(.show)', function () {
//            jQuery( this ).collapse( 'show' );
//        } ).find( '.collapse-peek-content' ).on( 'show.bs.collapse', function () {
//            jQuery( this ).addClass( 'is-expanding' );
//        } ).on( 'shown.bs.collapse', function () {
//            jQuery( this ).removeClass( 'is-expanding' );
//        } ).on( 'hide.bs.collapse', function () {
//            jQuery( this ).addClass( 'is-collapsing' );
//        } ).on( 'hidden.bs.collapse', function () {
//            jQuery( this ).removeClass( 'is-collapsing' );
//        } );
    },
    peekContent: function () {
//        jQuery( '.collapse-peek-content' ).each( function () {
//            var jThis = jQuery( this );
//            if ( this.scrollHeight <= jThis.height() * e4.settings.collapse.peekSizeMin ) {
//                jThis.closest( '.collapse-peek' ).addClass( 'collapse-peek-disabled' ).find( '[data-toggle="collapse"]' ).removeAttr( 'data-toggle' );
//                jThis.collapse( 'show' );
//            }
//        } );
    }
};

